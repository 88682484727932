import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Para = styled.p`
	font-size: 18px;
	font-weight: bold;
	line-height: 22px;
`;

const Para2 = styled(Para)`
	font-size: 18px;
	font-weight: normal;
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	cursor: pointer;
	background: #29386d;
	color: white;
	border: none;
	padding: 8px 24px;
	border-radius: 4px;
	font-weight: bold;
	font-size: 14px;
	text-decoration: none;
	margin-right: 8px;
	margin-bottom: 8px;
	width: 100%;
`;

const CloseButton = styled.button`
	cursor: pointer;
	border: 2px solid #092540 !important;
	color: #092540;
	border: none;
	padding: 0px 24px;
	border-radius: 4px;
	font-weight: bold;
	font-size: 14px;
	text-decoration: none;
	width: fit-content;
	height: 37px;
	@media (max-width: 528px) {
		width: 100% !important;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	@media (max-width: 528px) {
		flex-direction: column;
	}
`;

const HeadContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Heading = styled.h1`
	font-size: 24px;
`;

const ClientWebModal = ({ closeModal }) => {
	return (
		<>
			<HeadContainer>
				<Heading>IMPORTANT</Heading>
				<span onClick={closeModal} style={{ cursor: 'pointer' }}>
					X
				</span>
			</HeadContainer>
			<Para>
				From the 31st December 2022 this version of Client Web will be
				deprecated and will no longer be accessible.{' '}
			</Para>
			<Para2>
				Please use Client Web 7.8 which can be found on our Client Login
				screen and at link below.
			</Para2>
			<ButtonContainer>
				<>
					<Button
						href='https://clientweb.jacobsenforcement.com/cgi-bin/WebObjects/ClientWeb.woa'
						target='_blank'
					>
						CONTINUE
					</Button>
					<Button
						href='https://clientweb.jacobsenforcement.com/ClientWebv7/'
						target='_blank'
					>
						CLIENT WEB v.7.8
					</Button>
				</>
			</ButtonContainer>
		</>
	);
};

export default ClientWebModal;
